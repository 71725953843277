<template>
  <div class="home">
    <div class="header clearfix">
      <div class="desktop-top-header-area">
        <InfoMenu />
        <BrandMenu />
        <ServiceMenu />
        <NavMenu />
      </div>
      <div class="mobile-top-header-area">
        <InfoMenu />
        <BrandMenuMobile />
      </div>
    </div>
    <router-view></router-view>
    <FooterTopDescription />
    <FooterSocial />
    <FooterMenu />

  </div>
</template>

<script>
// @ is an alias to /src
import BrandMenuMobile from "@/views/header/BrandMenuMobile";
import InfoMenu from "@/views/header/InfoMenu";
import BrandMenu from "@/views/header/BrandMenu";
import ServiceMenu from "@/views/header/ServiceMenu";
import NavMenu from "@/views/header/NavMenu";
import FooterMenu from "@/views/footer/FooterMenu";
import FooterTopDescription from "@/views/footer/FooterTopDescription";
import FooterSocial from "@/views/footer/FooterSocial";
export default {
  name: 'MainPage',
  mounted() {
    console.log("Query Param", this.$route)
    if (Object.keys(this.$route.query).length !== 0) {
      let socialCr = {
        social_id: this.$route.query.social_id,
        social_type: this.$route.query.social_type
      };
      this.$store.dispatch("loginWithSocial", socialCr).then((res) => {
        if (res === undefined) {
          this.$toasted.success('Successfully Logedin.');
          //this.$router.push('/customer-dashboard').catch(() => {});

        } else {
          this.$toasted.error(res.message);
          this.resError = res.message;
        }
      });

    }
  },
  components: {
    BrandMenuMobile,
    InfoMenu,
    BrandMenu,
    ServiceMenu,
    NavMenu,
    FooterMenu,
    FooterTopDescription,
    FooterSocial
  }
}
</script>
